// Seats2.jsx
import React, { useState, useEffect } from "react";
import "./Foodpayment.css";
import axios from "../../api/axios";

const Foodpayment = () => {
  // State for selected players and verified players
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [verifiedPlayers, setVerifiedPlayers] = useState([]);

  // State for transaction IDs
  const [transactionId, setTransactionId] = useState("");
  const [addTransactionId, setAddTransactionId] = useState("");
  const [foodTransactionId, setFoodTransactionId] = useState("");
  const [addFoodTransactionId, setAddFoodTransactionId] = useState("");
  const [addFoodPayment, setAddFoodPayment] = useState("");

  // Handlers for transaction ID inputs
  const handleSearchTransactionId = () => {
    const searchTransactionId = async () => {
      try {
        const response = await axios.post(
          `/transactionid/checkfee`,
          { feetransactionid: transactionId },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        console.log("Transaction ID search response:", response.data);
        alert(response.data);
      } catch (error) {
        console.error("Error searching transaction ID:", error);
      }
    };
    searchTransactionId();
    console.log(transactionId);
  };

  // console.log(addTransactionId);
  const handleAddTransactionId = async () => {
    try {
      const response = await axios.post(
        `/transactionid/addfee`,
        { id: selectedPlayers, transactionId: addTransactionId },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log("Transaction ID search response:", response.data);
      alert(response.data);
    } catch (error) {
      console.error("Error adding transaction ID:", error);
      // Handle error here, e.g., display error message to the user
    }
  };

  const handleSearchFoodTransaction = () => {
    const searchFoodTransactionId = async () => {
      try {
        const response = await axios.post(
          `/transactionid/checkfood`,
          { foodtransactionid: foodTransactionId },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        console.log("Transaction ID search response:", response.data);
        alert(response.data);
      } catch (error) {
        console.error("Error searching transaction ID:", error);
      }
    };
    searchFoodTransactionId();
    console.log(foodTransactionId);
  };

  const handleAddFoodTransaction = () => {
    const AddTransactionId = async () => {
      try {
        const response = await axios.post(
          `/transactionid/addfood`,
          { id: selectedPlayers, foodtransactionId: addFoodTransactionId },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        console.log("Transaction ID search response:", response.data);
        alert(response.data);
      } catch (error) {
        console.error("Error searching transaction ID:", error);
      }
    };
    AddTransactionId();
    console.log(addFoodTransactionId);
  };

  const handleAddFoodPayment = () => {
    const AddTransactionId = async () => {
      try {
        const response = await axios.post(
          `/foodpayment`,
          { id: selectedPlayers, payment: addFoodPayment },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        console.log("Transaction ID search response:", response.data);
        alert(response.data);
      } catch (error) {
        console.error("Error searching transaction ID:", error);
      }
    };
    AddTransactionId();
    console.log(addFoodPayment);
  };

  // Effect for searching transaction ID

  // Handler for selecting players
  const handlePlayerClick = (playerId) => {
    // Check if the player is already selected
    if (!selectedPlayers.includes(playerId)) {
      // Add the player to the selected list
      setSelectedPlayers(playerId);
    }
  };

  // Effect for fetching verified players
  useEffect(() => {
    const fetchVerifiedPlayers = async () => {
      try {
        const response = await axios.get(`/registration/player`);
        const verifiedPlayersData = response.data.filter(
          (player) =>
            player.verification === true &&
            (player.feeTransactionId === null ||
              player.feeTransactionId === undefined ||
              player.foodTransactionId === null ||
              player.foodTransactionId === undefined)
        );
        setVerifiedPlayers(verifiedPlayersData);
      } catch (error) {
        console.error("Error fetching verified players:", error);
      }
    };

    fetchVerifiedPlayers();
  }, []);

  return (
    <>
      <div className="food-payment-main-core">
        <div className="food-payment-players-block">
          {verifiedPlayers.map((player, index) => (
            <div key={index}>
              <div
                className={`team-selection-players-team-1 ${
                  selectedPlayers.includes(player._id) ? "selected" : ""
                }`}
                onClick={(event) => handlePlayerClick(player._id, event)}
              >
                <h2>Player</h2>
                <p className="team-selection-players-name">
                  Name: {player.name}
                </p>
                <p className="team-selection-players-name">
                  Team Name: {player.team_name}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="food-payment-main-core">
          <div className="all-input-fields-payment">
            {/* Payment transaction ID */}
            <div className="payment-transaction-id">
              <input
                type="text"
                placeholder="Payment transaction ID"
                className="transaction-id-input"
                value={transactionId}
                onChange={(event) => setTransactionId(event.target.value)}
              />
              <button
                className="transaction-id-search"
                onClick={handleSearchTransactionId}
              >
                Search
              </button>
            </div>

            {/* Add payment transaction ID */}
            <div className="add-payment-transaction-id">
              <input
                type="text"
                placeholder="Add payment transaction ID"
                className="add-transaction-id-input"
                value={addTransactionId}
                onChange={(event) => setAddTransactionId(event.target.value)}
              />
              <button
                className="transaction-id-submit"
                onClick={handleAddTransactionId}
              >
                Submit
              </button>
            </div>

            {/* Food transaction ID */}
            <div className="food-payment-transaction-id">
              <input
                type="text"
                placeholder="Food transaction ID"
                className="food-transaction-id-input"
                value={foodTransactionId}
                onChange={(event) => setFoodTransactionId(event.target.value)}
              />
              <button
                className="food-transaction-id-search"
                onClick={handleSearchFoodTransaction}
              >
                Search
              </button>
            </div>

            {/* Add food transaction ID */}
            <div className="add-food-payment-transaction-id">
              <input
                type="text"
                placeholder="Add food transaction ID"
                className="add-food-transaction-id-input"
                value={addFoodTransactionId}
                onChange={(event) =>
                  setAddFoodTransactionId(event.target.value)
                }
              />
              <button
                className="food-transaction-id-submit"
                onClick={handleAddFoodTransaction}
              >
                Submit
              </button>
            </div>

            {/* Paid amount input */}
            <div className="player-food-payment-input">
              <input
                className="food-payment-input"
                placeholder="Paid Amount"
                type="text"
                value={addFoodPayment}
                onChange={(event) => setAddFoodPayment(event.target.value)}
              />
            </div>
            {/* Pay button */}
            <div className="pay-button-food-main">
              <button
                className="pay-button-food"
                onClick={handleAddFoodPayment}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Foodpayment;
