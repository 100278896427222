import axios from 'axios';
// const BASE_URL = 'http://mirage-backend.ddns.net:3500';
const BASE_URL = 'https://mirage-website-backend.onrender.com';
// const BASE_URL = 'https://backend.mirage-cgcj.tech';
// const BASE_URL = 'http://192.168.69.2:3500';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
