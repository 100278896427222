import React, { useState,useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import io from 'socket.io-client';
import './Qrscan.css';
import axios from "../../api/axios";


const QRScanner = () => {
    const [scannedResult, setScannedResult] = useState(null);
    const [messages, setMessages] = useState([]);
    const socket = io('http://192.168.69.2:4000');

    const handleError = err => {
        console.error(err);
    };

    const handleScan = async (data) => {
        if (data) {
            const id = data.text
            
            setScannedResult(id);
            
        }
    };

    const handleClick = async () => {
        try{
            const response = await axios.post(`/registration/team/${scannedResult}`);
            console.log(response.data)
            sendMessage(response.data)
    }
    catch (err){
        console.log(err)
    }
}

    useEffect(() => {
        socket.on('qr data', (msg) => {
            setMessages([msg]);
        });
    
        // Cleanup function to unsubscribe from socket events on unmount
        return () => {
            socket.off('qr data');
        };
    }, []);
    
    const sendMessage = (data) => {
        socket.emit('qr data', data);
    }







    return (
        <div className="qrscan-main">
            
            <div className='qrscan-core'>
                <div className='qrscan-card'>
                    <h1 className='qrscan-h1'>Scan QR Code</h1>
                    <div className='qrscan-scanner-container'>
                        <QrScanner
                        delay={5000}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%',height:'100%',textAlign: 'center' }}
                        />
                    </div>
                    
                </div>
                <div className='qrscan-input-container'>
                    <input type="text" value={scannedResult} />
                    <button onClick={handleClick}>Fetch Data</button>
                </div>
            </div>
        
        </div>
    );
};

export default QRScanner;
