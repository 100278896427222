import {React} from 'react';
import './Home.css';
import ellipse from '../../assets/Misc/ellipse-12.svg';
import miragelogo from '../../assets/Core/Mirage Logo - Transparent.png';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth'
import useLogout from '../../hooks/useLogout';






const Home = () => {
  const navigate = useNavigate();
  const {auth} = useAuth();


  const logout = useLogout();

  const handleLogout = async (e) => {
    await logout();
  }
  

  return (
    <div className='home-main'>
      <div className="home-core">
        
        <div className='home-hero'>
          <div className='home-hero-logo'>
            
            <img className="home-ellipse-img" alt="" src={ellipse} />
            <img className="home-halolegion-logo" alt="" src={miragelogo} />
            <div className="home-hero-text">
              <h1 className="home-hero-halocon-text">MIR  AGE</h1>
            </div>
          </div>
        </div>
        <div className='home-bottom'>
        
        <div className='home-info-text-container'>
          <p className="home-info-text">
              Illusions Collide, Victory Inside
              
          </p>
        </div>
        <div className="home-button-container">
          {auth && !auth.accessToken ? (
            <>
              <button className="home-cssbuttons-io-button" onClick={() => { navigate("/signin"); }}>
                LOGIN
                <div className="home-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                  </svg>
                </div>
              </button>
            </>
          ) : (
            <>
              <button className="home-cssbuttons-io-button" onClick={() => { navigate("/mode"); }}>
                  MODES
                  <div className="home-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                    </svg>
                  </div>
              </button>
              <button className="home-cssbuttons-io-button home-logout-button" onClick={handleLogout}>
                LOGOUT
                <div className="home-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                  </svg>
                </div>
              </button>
              
            </>
          )}
        </div>

        </div>
      </div>
    </div>
  );
}

export default Home;
