// Seats2.jsx
import React, { useState, useEffect } from "react";
import "./Seats.css";
import axios from "../../api/axios";
import exitleft from "../../assets/icons8/icons8-exit-left.png";
import exitright from "../../assets/icons8/icons8-exit-right.png";
import entry from "../../assets/icons8/icons8-door.png";
import SeatDialog from "./SeatDialog";

const Seats2 = () => {
  // Define the number of rows and seats in a row
  const SEAT_URL = "/seats";
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [seatsToBook, setSeatsToBook] = useState(1);
  const [seats, setSeats] = useState([]);
  const [error, setError] = useState();
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [verifiedPlayers, setVerifiedPlayers] = useState([]);
  const [hoveredSeat, setHoveredSeat] = useState(null); // State for the hovered seat
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 }); // Position for the dialog box
  const [hoveredPlayerData, setHoveredPlayerData] = useState();





  const handleSeatHover = async (seat, event) => {
    setHoveredSeat(seat);

    if (seat.status === "Booked") {
        const response = await axios.get(`/players/${seat.playerId}`);
        setHoveredPlayerData(response.data);
    } else {
        setHoveredPlayerData(null);
    }

    const hoverOffsetTop = 25;
    const hoverOffsetBottom = 25; // Adjust the position as needed
    const hoverOffsetLeft = 275;
    const hoverOffsetRight = 5;  // Adjust the position as needed
    const hoverWidth = 275;       // Width of the hover dialog
    const hoverHeight = 275;      // Height of the hover dialog (adjust based on your design)

    let position;

    if (window.innerWidth - event.clientX < hoverWidth) {
        // Not enough space on the right, position tooltip to the right
        position = {
            right: window.innerWidth - event.clientX + hoverOffsetRight,
        };
    } else {
        // Enough space on the right, position tooltip to the left
        position = {
            left: event.clientX - hoverOffsetLeft,
        };
    }

    if (window.innerHeight - event.clientY < hoverHeight) {
        // Not enough space at the bottom, position tooltip above the cursor
        // console.log(event.clientY - hoverOffsetTop)
        position.bottom = window.innerHeight - event.clientY + hoverOffsetBottom;
        
    } else {
        // Enough space at the bottom, positinon tooltip below the cursor
        position.top = event.clientY - hoverOffsetTop;
    }

    setHoverPosition(position);
};



  
  
  
  
  const handleSeatLeave = () => {
    setHoveredSeat(null);
  };



  const handlePlayerClick = (playerId, event) => {
    
    if (event.ctrlKey || event.metaKey) {
      if (selectedPlayers.includes(playerId)) {
        setSelectedPlayers(selectedPlayers.filter((id) => id !== playerId));
        
      } else {
        setSelectedPlayers([...selectedPlayers, playerId]);
      }
    } else {
      if (selectedPlayers.includes(playerId)) {
        setSelectedPlayers(selectedPlayers.filter((id) => id !== playerId));
      }
      else{
        setSelectedPlayers([playerId]);
      }
      
    }
    
  };

  useEffect(()=>{
    setSeatsToBook(selectedPlayers.length)
    // console.log(selectedPlayers.length)
  },[selectedPlayers])
  
  
  const handlestatusupdate = async () => {
    if (selectedSeats.length === 0) {
      setError("Please select at least one seat before proceeding.");
      return;
    }

    const SeatsPlayerdata = selectedSeats.reduce((acc, selectedSeat, index) => {
      acc[index] = {
          Playerbooked: selectedPlayers[index],
          selectedSeats: selectedSeat,
      };
      return acc;
    }, {});
    
    
    try {
      const response = await axios.post(`${SEAT_URL}/status`,
        SeatsPlayerdata,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log("Seat booking response:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error sending seat booking request:", error);
    }
  };

  // Function to handle dropdown change
  const handleDropdownChange = (event) => {
    const selectedSeatsCount = parseInt(event.target.value, 10);
    setSeatsToBook(selectedSeatsCount);
  };

  const handleDeselectAll = () => {
    setSelectedSeats([]);
    setSelectedPlayers([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(SEAT_URL);
        setSeats(response.data);
        // console.log("Updated Seats:", response.data);
      } catch (error) {
        console.error("Error fetching or updating seat data:", error);
      }
    };

    // Call the async function only on component mount
    fetchData();
  }, []);

  // useEffect(() => {
  //   console.log("Updated Seats", seats);
  // }, [seats]);

  useEffect(() => {
    setSelectedSeats([]);
  }, [seatsToBook]);


  useEffect(()=>{
    // console.log(selectedSeats)
  },[selectedSeats])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError("");
    }, 2000);
    return () => clearTimeout(timeout);
  }, [error]);
  
  useEffect(() => {
    const fetchDataforSelection = async () => {
      try {
        const response = await axios.get(
          `/registration/player`
        );
        const verifiedPlayersData = response.data.filter(
          (player) =>
            player.verification === true &&
            (player.seatname === null || player.seatname === undefined)
        );
        setVerifiedPlayers(verifiedPlayersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataforSelection();
  }, []);

  const renderSeatsInRows = () => {
    const rows = {};
    seats.sort((a, b) => {
      const rowComparison = a.seatRow.localeCompare(b.seatRow);
      if (rowComparison !== 0) return rowComparison;
  
      const seatNumberA = parseInt(a.seatNumber, 10);
      const seatNumberB = parseInt(b.seatNumber, 10);
  
      return seatNumberA - seatNumberB;
    });
    // Group seats by row
    seats.forEach((seat) => {
      const { seatRow } = seat;
      const isSelected = selectedSeats.includes(seat._id);
      if (!rows[seatRow]) {
        rows[seatRow] = [];
      }
      rows[seatRow].push({ ...seat, isSelected });
    });
      

    return (
      <div className="seats-container">
        <div className="seats-top-control">
          <img className="seats-icon-exit" src={exitleft} alt="" />
          <div className="seats-top-center-control">
            <div className="seats-rows-container-control">
              {Object.entries(rows).reverse().slice(0, 1).map(([row, rowSeats]) => (
                    <div key={`row-${row}`} className="seat-rows-container-control">
                      <div className="seat-row-control-left">
                        {rowSeats.slice(0, 6).reverse().map((seat) => (
                            <div 
                              key={seat._id} 
                              className={`seat seat-control seat-top seat-${seat.status} seat-${seat.isSelected ? "selected" : ""}`}
                              onClick={() =>seat.status === "Available" &&handleSeatClick(seat._id)}
                              onMouseEnter={(e) => handleSeatHover(seat, e)}
                              onMouseLeave={handleSeatLeave}
                            >
                              {row}
                              {seat.seatNumber}
                            </div>
                          ))}
                      </div>
                      <div className="seat-row-control-left">
                        {rowSeats
                          .slice(6)
                          .reverse()
                          .map((seat) => (
                            <div
                              key={seat._id}
                              className={`seat seat-control seat-top seat-${
                                seat.status
                              } seat-${seat.isSelected ? "selected" : ""}`}
                              onClick={() =>
                                seat.status === "Available" &&
                                handleSeatClick(seat._id)
                              }
                              onMouseEnter={(e) => handleSeatHover(seat, e)}
                              onMouseLeave={handleSeatLeave}                              
                            >
                              {row}
                              {seat.seatNumber}
                            </div>
                          ))}
                      </div>
                    </div>
                ))}
            </div>
            <div className="control">Control Room</div>
            <div className="seats-rows-container-control">
              {Object.entries(rows).reverse().slice(1, 2).map(([row, rowSeats]) => (
                    <div
                      key={`row-${row}`}
                      className="seat-rows-container-control"
                    >
                      <div className="seat-row-control-left">
                        {rowSeats
                          .slice(0, 6)
                          .reverse()
                          .map((seat) => (
                            <div
                              key={seat._id}
                              className={`seat seat-control seat-top seat-${
                                seat.status
                              } seat-${seat.isSelected ? "selected" : ""}`}
                              onClick={() =>
                                seat.status === "Available" &&
                                handleSeatClick(seat._id)
                              }
                              onMouseEnter={(e) => handleSeatHover(seat, e)}
                              onMouseLeave={handleSeatLeave}                              
                            >
                              {row}
                              {seat.seatNumber}
                            </div>
                          ))}
                      </div>
                      <div className="seat-row-control-right">
                        {rowSeats
                          .slice(6)
                          .reverse()
                          .map((seat) => (
                            <div
                              key={seat._id}
                              className={`seat seat-control seat-top seat-${
                                seat.status
                              } seat-${seat.isSelected ? "selected" : ""}`}
                              onClick={() =>
                                seat.status === "Available" &&
                                handleSeatClick(seat._id)
                              }
                              onMouseEnter={(e) => handleSeatHover(seat, e)}
                              onMouseLeave={handleSeatLeave}                              
                            >
                              {row}
                              {seat.seatNumber}
                            </div>
                          ))}
                      </div>
                    </div>
                ))}
            </div>
          </div>
          <img className="seats-icon-exit" src={exitright} alt="" />
        </div>
        <div className="seats-top-container">
          <div className="seats-rows-index-container">
            {Object.entries(rows).slice(9,19).reverse().map(([row, rowSeats]) => (
                  <div key={`row-${row}`} className="seat-rows-container">
                    <div className="seat-row-index">{row}</div>
                  </div>
              ))}
          </div>
          <div className="seats-top">
            <div className="seats-rows-container">
              {Object.entries(rows).reverse().slice(2, 12).map(([row, rowSeats]) => (
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(13).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                ))}
            </div>
            <div className="seats-rows-container">
              {Object.entries(rows)
                .reverse()
                .slice(2, 12)
                .map(([row, rowSeats]) => (
                  <div>
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(0,13).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}                            
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="seats-top-container">
          <div className="seats-rows-index-container">
            {Object.entries(rows).slice(2,9).reverse().map(([row, rowSeats]) => (
                  <div key={`row-${row}`} className="seat-rows-container">
                    <div className="seat-row-index">{row}</div>
                  </div>
              ))}
          </div>
          <div className="seats-top">
            <div className="seats-rows-container">
              {Object.entries(rows).slice(2, 9).reverse().map(([row, rowSeats]) => (
                  <div>
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(23).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}                            
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="seats-rows-container">
              {Object.entries(rows).slice(2, 9).reverse().map(([row, rowSeats]) => (
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(8,23).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                ))}
            </div>
            <div className="seats-rows-container">
              {Object.entries(rows).slice(2, 9).reverse().map(([row, rowSeats]) => (
                  <div>
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(0,8).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="seats-top-container">
          <div className="seats-rows-index-container">
            {Object.entries(rows).slice(0,2).reverse().map(([row, rowSeats]) => (
                  <div key={`row-${row}`} className="seat-rows-container">
                    <div className="seat-row-index">{row}</div>
                  </div>
              ))}
          </div>
          <div className="seats-top" id="bottom">
            
            <div className="seats-rows-container" id="bottom-left">
              {Object.entries(rows).slice(0,2).reverse().map(([row, rowSeats]) => (
                  <div>
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(21).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="seats-rows-container" >
              {Object.entries(rows).slice(0,2).reverse().map(([row, rowSeats]) => (
                  <div key={`row-${row}`} className="seat-rows-container">
                    <div className="seat-row">
                      {rowSeats.slice(6,21).reverse().map((seat) => (
                        <div
                          key={seat._id}
                          className={`seat seat-top seat-${seat.status} seat-${
                            seat.isSelected ? "selected" : ""
                          }`}
                          onClick={() =>
                            seat.status === "Available" &&
                            handleSeatClick(seat._id)
                          }
                          onMouseEnter={(e) => handleSeatHover(seat, e)}
                          onMouseLeave={handleSeatLeave}
                        >
                          {seat.seatNumber}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="seats-rows-container" id="bottom-right">
              {Object.entries(rows).slice(0,2).reverse().map(([row, rowSeats]) => (
                  <div>
                    <div key={`row-${row}`} className="seat-rows-container">
                      <div className="seat-row">
                        {rowSeats.slice(0,6).reverse().map((seat) => (
                          <div
                            key={seat._id}
                            className={`seat seat-top seat-${seat.status} seat-${
                              seat.isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              seat.status === "Available" &&
                              handleSeatClick(seat._id)
                            }
                            onMouseEnter={(e) => handleSeatHover(seat, e)}
                            onMouseLeave={handleSeatLeave}                            
                          >
                            {seat.seatNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        
        
        
      </div>
    );
  };

  const handleSeatClick = (seatId) => {
    const clickedSeat = seats.find((seat) => seat._id === seatId);
    // console.log(clickedSeat);
    const seatIndex = seats.indexOf(clickedSeat);

    if (seatIndex !== -1) {
      const endIndex = seatIndex; // Use clicked seat as endIndex
      const startIndex = endIndex - seatsToBook + 1; // Calculate startIndex accordingly

      if (startIndex >= 0) {
        // Ensure startIndex is within bounds
        const consecutiveSeatsAvailable = seats
          .slice(startIndex, endIndex + 1)
          .every((seat) => seat.status === "Available");

        if (consecutiveSeatsAvailable) {
          setSelectedSeats([]);

          const consecutiveSeatIds = seats
            .slice(startIndex, endIndex + 1)
            .map((seat) => seat._id);

          setSelectedSeats(consecutiveSeatIds);
        } else {
          setError(
            "Consecutive seats are not available. Either reduce the number of seats to book or select other seats."
          );
          console.log("Cannot select consecutive seats");
        }
      } else {
        console.log("Cannot select consecutive seats");
        const startIndex = seatIndex; // Use clicked seat as endIndex
        const endIndex = startIndex + seatsToBook - 1; // Calculate startIndex accordingly

        const consecutiveSeatsAvailable = seats
          .slice(startIndex, endIndex + 1)
          .every((seat) => seat.status === "Available");

        if (consecutiveSeatsAvailable) {
          setSelectedSeats([]);

          const consecutiveSeatIds = seats
            .slice(startIndex, endIndex + 1)
            .map((seat) => seat._id);

          setSelectedSeats(consecutiveSeatIds);
        } else {
          setError(
            "Consecutive seats are not available. Either reduce the number of seats to book or select other seats."
          );
          console.log("Cannot select consecutive seats");
        }
      }
    }
  };

  return (
    <div className="seats-main">
      
      <p>{error}</p>
      <div className="seats-core">
        <div className="seats-core-legend">
          <div>
            <ul className="showcase">
              <li>
                <div className="seats-showcase-seat"></div>
                <small>Available</small>
              </li>
              <li>
                <div className="seats-showcase-seat selected"></div>
                <small>Selected</small>
              </li>
              <li>
                <div className="seats-showcase-seat sold"></div>
                <small>Sold</small>
              </li>
            </ul>
          </div>
          <button onClick={handleDeselectAll}>Deselect All</button>
          <div className="seats-dropdown">
            <p className="seats-dropdown-text" htmlFor="seatsDropdown">Number of Seats to Book:</p>
            <select
              id="seatsDropdown"
              value={seatsToBook}
              onChange={handleDropdownChange}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          </div>
          <button
            className="seats-cssbuttons-io-button"
            onClick={handlestatusupdate}
          >
            {" "}
            NEXT
            <div className="seats-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  fill="currentColor"
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                ></path>
              </svg>
            </div>
          </button>
        </div>
        <div className="seats-core-container">
          <div className="team-selection-block">
            <h3 className="team-selection-title">Team Names</h3>
            <div className="team-selection-players-block">
                <div>
                  {verifiedPlayers.map((player, index) => (
                    <div key={index}>
                      <div
                        className={`team-selection-players-team-1 ${
                          selectedPlayers.includes(player._id) ? "selected" : ""
                        }`}
                        onClick={(event) => handlePlayerClick(player._id, event)}
                      >
                        <p className="team-selection-players-name">
                          Name: {player.name}
                        </p>
                        <p className="team-selection-players-name">
                          Team Name: {player.team_name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
          </div>
          
          <div className="seats-container-main">
            <div className="seats-container-core">
              {renderSeatsInRows()}
              <div className="screen-container">
                <div className="screen" />
                <img className="seats-icon-entry" src={entry} alt="" />
              </div>
              <SeatDialog seat={hoveredSeat} position={hoverPosition} playerData={hoveredPlayerData}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seats2;
