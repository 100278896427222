import React from 'react';
import { Home, Foodpayment, Verify,QrCode, Mode,Barcode, Seats, Signin, Signup, Dashboard, Unauthorised, Pagenotfound, Profile, ForgotPass } from './Pages';
import Layout from './components/Layout';
import './App.css';
import { Routes, Route } from 'react-router-dom';
// import RequireAuth from './hooks/RequireAuth';
import PersistLogin from './Pages/Sign-in/PersistLogin';

export const ROLES = [
  'User',
  'Admin',
  'Editor',
  'Owner',
]

const App = ({ labeledFaceDescriptions }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>

          {/* public routes*/}

          <Route path='/' element={<Signin />} />
          <Route path='/forgotpass' element={<ForgotPass />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/unauthorised' element={<Unauthorised />} />
          <Route path='/pagenotfound' element={<Pagenotfound />} />
          <Route path='/verification' element={<Verify />} />  
          <Route path='/Foodpayment' element={<Foodpayment />} />
          <Route path='/b' element={<Barcode />} />


          <Route path='/qr' element={<QrCode />} />
          {/* <Route path='/Verify' element={<Verify />} /> */}

          <Route element={<PersistLogin />}>
            {/* <Route path='/' element={<Home />} /> */}
            <Route path='/home' element={<Home />} />
            <Route path='/mode' element={<Mode />} />
            <Route path='/profile' element={<Profile />} />

            {/* <Route element={<RequireAuth allowedroles={[ROLES.Admin]} />}> */}
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>

          {/* <Route element={<RequireAuth allowedroles={[ROLES.User]} />}> */}
            {/* <Route element={<RequireAuth allowedroles={[ROLES.User]} />}> */}
              
              <Route path='/seats' element={<Seats />} />
              {/* <Route path='/foodpayment' element={<food_payment />} /> */}
            {/* </Route> */}

          <Route path='/seats' element={<Seats />} />
          {/* <Route path='/foodpayment' element={<food_payment />} /> */}
        </Route>

        {/* </Route> */}
        {/* <Route path='*' element={<Pagenotfound />} /> */}


        {/* </Route> */}
      </Routes>

    </>
  )
}

export default App;
