import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ROLES } from '../../App';
import useAuth from '../../hooks/useAuth'; // Import useAuth hook from the correct path

export const Menu = () => {
  const { auth } = useAuth(); // Destructure auth object from useAuth hook
  const [hasUserRole, setHasUserRole] = useState(false); // Rename variables to follow camelCase convention
  const [hasAdminRole, setHasAdminRole] = useState(false);

  useEffect(() => {
    if (auth.roles !== undefined ) {
      const authRoles = auth.roles;
      // Check if the user has User role
      if (authRoles.includes("User")) {
        setHasUserRole(true);
      } else {
        setHasUserRole(false);
      }

      if (authRoles.includes("Admin")) {
        setHasAdminRole(true);
      } else {
        setHasAdminRole(false);
      }
    } else {
      setHasUserRole(false);
      setHasAdminRole(false);
    }
  }, [auth.roles, auth.Roles]); // Add auth.Roles to the dependency array

  return (
    <>
      <Link to="/home"><p className='navbar-home-text'>Home</p></Link>
      {hasUserRole && 
      <>
        <Link to="/f"><p className='navbar-events-text'>Face</p></Link>
        <Link to="/seats"><p className='navbar-events-text'>Seats</p></Link>
        <Link to="/b"><p className='navbar-events-text'>Barcode </p></Link>
        <Link to="/qr"><p className='navbar-events-text'>QR Code </p></Link>
        </>
        }
    </>
  );
};
