import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import './Verify.css';
import io from 'socket.io-client';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpAZ,faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const Team_profile = (playerdata) => {
  const PLAYER_URL = "/players";
  const socket = io('http://localhost:4000');

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [messages, setMessages] = useState([]);


  const [verifiedPlayers, setVerifiedPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);  // State for loading animation
  const [vloading, setVLoading] = useState(false);
  const [imagezoom, setImageZoom] = useState(false);
  const [zoomimg, setZoomImg] = useState(0);
  const [filteredplayers,setFilteredPlayers] = useState([]);
  const [sortmode,setSortMode] = useState("Alphabetical")
  
  
  const handlesortmode = () =>{
    setSortMode(prevSortMode => prevSortMode === 'Alphabetical' ? 'GroupByTeam' : 'Alphabetical');
  }


  useEffect(() => {
      fetchDataforSelection();
  }, []);



  useEffect(()=>{
    console.log(sortmode);
    if (sortmode === "Alphabetical"){
      const sortedPlayers = sortPlayersByName(verifiedPlayers);
      setVerifiedPlayers(sortedPlayers);
    }
    else if (sortmode === "GroupByTeam") {
      const sortedPlayers = sortPlayersByTeamAndName(verifiedPlayers);
      setVerifiedPlayers(sortedPlayers);
    }
  },[sortmode])

  useEffect(() => {
    socket.on('qr data', async (data) => {
      // console.log(data.players)
      setFilteredPlayers([])
      const team_players = await getplayerdatabyteamid(data)
      setFilteredPlayers(team_players);
    });
    

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('qr data');
    };
  }, []);

  
  const sortPlayersByTeamAndName = (players) => {
    return players.sort((a, b) => {
      if (a.team_name < b.team_name) return -1;
      if (a.team_name > b.team_name) return 1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  };
  
  const sortPlayersByName = (players) => {
    return players.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  };

  const sendMessage = () => {
      socket.emit('qr data', "gogo2345");
  }

  const getplayerdatabyteamid = async (data) => {
    const team_player = []
    if(data){
      for (let player of data.players){
        const response = await axios.post(`/registration/player/${player._id}`);
        const playerdata = response.data
        
        // console.log(playerdata)
        // console.log(verifiedPlayers)
        team_player.push(playerdata);
        
        
        
      }
    }
    return team_player
  }




  const handleverify = async (playerId) => {
    setVLoading(true)
    console.log(playerId)
    console.log(selectedPlayers.filter((id) => id !== playerId))
    
    try {
      const response = await axios.put(`${PLAYER_URL}/${playerId}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setTimeout(function() {
        if (response.status === 200) {
          fetchDataforSelection();
          setSelectedPlayers([]);
          setVLoading(false);
          
        }else{
          window.location.reload();
        }
    }, 3000);
    
    setVLoading(false)
      
    } catch (error) {
      console.error("Error sending seat booking request:", error);
    }
    
  };

  const handlePlayerClick = (player) => {
    // console.log(player)
    if (selectedPlayers.includes(player)) {
      setSelectedPlayers(selectedPlayers.filter((id) => id !== player._id));
    } else {
      setSelectedPlayers([player]);
    }
  };

 useEffect(() => {
    console.log(selectedPlayers);
  }, [selectedPlayers]);
  
  
  useEffect( () => {
    if (searchQuery === undefined){
      // console.log('serach is undef')
      setFilteredPlayers(verifiedPlayers);
    }
    else if (searchQuery===''){
      // console.log('serach is empty')
      setFilteredPlayers(verifiedPlayers);
    }
    else{
      const filteredPlayers = verifiedPlayers.filter(player =>
        player.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        player.team_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        player._id.includes(searchQuery)
      );
      setFilteredPlayers(filteredPlayers)
    }
  },[searchQuery,verifiedPlayers])

  useEffect(()=>{
    // console.log(filteredplayers)
  },[filteredplayers])




  // if (searchQuery===''){
  //   console.log(`asdasda:${searchQuery}`)
  // }

  const fetchDataforSelection = async () => {
    console.log("running")
    try {
      const response = await axios.get(`/registration/player`);
      const verifiedPlayersData = response.data.filter(
        (player) => player.verification === false
      );
      const registeredPlayers = response.data.filter(
        (player) => player.barcode !== null
      )
      console.log(registeredPlayers)
      if (sortmode === "Alphabetical"){
        const sortedPlayers = sortPlayersByName(verifiedPlayersData.concat(registeredPlayers));
        setVerifiedPlayers(sortedPlayers);
      }
      else if (sortmode === "GroupByTeam") {
        const sortedPlayers = sortPlayersByTeamAndName(verifiedPlayersData.concat(registeredPlayers));
        setVerifiedPlayers(sortedPlayers);
      }
      
      // setVerifiedPlayers(verifiedPlayersData);
      // setVerifiedPlayers(verifiedPlayersData.concat(registeredPlayers));
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  
  
  

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };
  

  return (
    <div className="verify-main">
      <div className="Team_profile">
        <div className="verify_team-selector-main-core">
          <div className="verify_team-selection-block">
            <div className="verify_team_top-container">
              <h1 className="verify_teams-h1">Players</h1>
              {/* <input className="verify_search-bar" autocomplete="off" type="text" placeholder="Search players..." value={searchQuery} onChange={handleSearch} /> */}
              <form className="verify_search-form">
                <label for="search">
                  <input className="verify_search-bar" type="text" placeholder="Search players..." value={searchQuery} onChange={handleSearch} />
                    <div className="verify_search_icon">
                        <svg stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="verify_search-swap-on">
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" stroke-linejoin="round" stroke-linecap="round"></path>
                        </svg>
                        <svg stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="verify_search-swap-off">
                            <path d="M10 19l-7-7m0 0l7-7m-7 7h18" stroke-linejoin="round" stroke-linecap="round"></path>
                        </svg>
                    </div>
                    <button type="reset" className="verify_search-close-btn">
                        <svg viewBox="0 0 20 20" className="verify_search-h-5 verify_search-w-5" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" fill-rule="evenodd"></path>
                        </svg>
                    </button>
                </label>
              </form>
              <div className="barcode-sort" >
                {sortmode !== "Alphabetical" && (
                  <FontAwesomeIcon className="barcode-sort-icon" onClick={handlesortmode} icon={faArrowUpAZ} />
                )}
                {sortmode !== "GroupByTeam" && (
                  <FontAwesomeIcon className="barcode-sort-icon" onClick={handlesortmode} icon={faPeopleGroup} />
                )}
              </div>
            </div>
            <div className="verify_team-selection-players-block">
            <div className="verify_player_container">
              {filteredplayers.map((player, index) => (
                (player.name).trim() !== "" && (
                  <div key={index}>
                    <div
                      className={`verify_team-selection-players-team-1 ${
                        selectedPlayers.includes(player._id) ? "selected" : ""
                      }`}
                      onClick={() => handlePlayerClick(player)}
                    >
                      <img className="verify_team_player-pfp" src={player.pfp} alt="" />
                      <div className="verify_team_selection-player-name-container">
                        <div className="verify_team_selection-player-name-section">
                          <p className="verify_team-selection-players-name">Name:</p>
                          <p className="verify_team-selection-players-name">Team Name:</p>
                        </div>
                        <div className="verify_team_selection-player-name-section">
                          <p className="verify_team-selection-players-name2">{player.name}</p>
                          <p className="verify_team-selection-players-name2">{player.team_name}</p>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                )
              ))}
            </div>
            </div>
          </div>
          <div className="verify_seats-container-main">
          { vloading === true ? (
            <div className="verify-loaderverification-container">
              <div className="verify-loaderverification-top">
                <div className="verify-loaderverification-square">
                  <div className="verify-loaderverification-square">
                    <div className="verify-loaderverification-square">
                      <div className="verify-loaderverification-square">
                        <div className="verify-loaderverification-square"><div className="verify-loaderverification-square">
                        </div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="verify-loaderverification-bottom">
                <div className="verify-loaderverification-square">
                  <div className="verify-loaderverification-square">
                    <div className="verify-loaderverification-square">
                      <div className="verify-loaderverification-square">
                        <div className="verify-loaderverification-square"><div className="verify-loaderverification-square">
                        </div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="verify-loaderverification-left">
                <div className="verify-loaderverification-square">
                  <div className="verify-loaderverification-square">
                    <div className="verify-loaderverification-square">
                      <div className="verify-loaderverification-square">
                        <div className="verify-loaderverification-square"><div className="verify-loaderverification-square">
                        </div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="verify-loaderverification-right">
                <div className="verify-loaderverification-square">
                  <div className="verify-loaderverification-square">
                    <div className="verify-loaderverification-square">
                      <div className="verify-loaderverification-square">
                        <div className="verify-loaderverification-square"><div className="verify-loaderverification-square">
                        </div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ):(
              <>
                {selectedPlayers.map((player, index) => (
                  <div className='verify_seats-container-core' key={index}>
                    <div className='verify_screen-container'>
                      {loading && (
                        <div className="spinner">Loading...</div>
                      )}
                      <img className='team_profile_img1'src={player.pfp} alt="" onClick={()=>{setImageZoom(true);setZoomImg(1);}} onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }}/>
                      <img className='team_profile_img2'src={player.IdCard} alt="" onClick={()=>{setImageZoom(true);setZoomImg(2);}} onLoad={handleImageLoad}  style={{ display: loading ? 'none' : 'block' }}/>
                      
                      
                      
                      
                    </div>
                    <div className="verify_team_details">
                      <div className="verify_team_details-flex">
                          <h2 className='verify_player_details'>Player Name:</h2>
                          <h2 className='verify_player_details2'>{player.name}</h2>
                      </div>
                      <div className="verify_team_details-flex">
                          <h2 className='verify_player_details'>Team Name:</h2>
                          <h2 className='verify_player_details2'>{player.team_name}</h2>
                      </div>
                      <div className="verify_team_details-flex">
                          <h2 className='verify_player_details'>Player ID:</h2>
                          <h2 className='verify_player_details2'>{player._id}</h2>
                      </div>
                      {player.verification === true ? (
                        <div className="verify_team_details-flex">
                          <h2 className="verify_player_details">Player ID:</h2>
                          <h2 className="verify_player_details2">True</h2>
                        </div>
                      ) : (
                        <div className="verify_team_details-flex">
                          <h2 className="verify_player_details">Verification Status:</h2>
                          <h2 className="verify_player_details2">False</h2>
                        </div>
                      )}

                      
                      
                    </div>
                    <div className="verify_button-block">
                      {!player.verification === true ?(
                        <button className="animated-button" onClick={() => handleverify(player._id)}>
                          <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                          </svg>
                          <span className="text">Verify</span>
                          <span className="circle"></span>
                          <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                          </svg>
                        </button>
                      ):(
                          <label class="verify_button-container">
                            <input type="checkbox" checked="checked"/>
                            <div class="verify_button-checkmark"></div>
                          </label>
                      )}
                      

                    </div>
                  </div>
                ))}
              </>
            )}
            </div>
        </div>
        {imagezoom &&(
          <div className="verify-image-zoom-main" onClick={() => setImageZoom(false)}>
          <div className="verify-image-container-top">

            <button class="verify-image-zoom-close-button" onClick={() => setImageZoom(false)}>
              <span class="verify-image-zoom-close-X"></span>
              <span class="verify-image-zoom-close-Y"></span>
              <div class="verify-image-zoom-close-close">Close</div>
            </button>

          </div>
          
          {selectedPlayers.map((player, index) => (
            <div className="verify-image-zoom-container">
              {zoomimg === 1 &&(
                <img className='team_profile_img-zoom'src={player.pfp} alt="" onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }}/>
              )}
              {zoomimg === 2 &&(
                <img className='team_profile_img-zoom'src={player.IdCard} alt="" onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }}/>
              )}
            </div>
          ))}
          
          </div>
        )}
      </div>
    </div>
  );
};

export default Team_profile;
