import React from 'react';
import './Mode.css';
import verify_img from '../../assets/Core/icons8-detective-96.png'
import FnB_img from '../../assets/Core/icons8-food-and-drink-96.png'
import Allocate_img from '../../assets/Core/icons8-id-card-96.png'
import { useNavigate } from 'react-router-dom';

const Events = () => {
    const navigate = useNavigate();

    return (
        <div className='events-main'>
            <div className='events-core'>
                
                <div className='events-cards-row'>
                    <div className='events-vertical-card' onClick={() => { navigate("/verification"); }}>
                        <div className='events-vertical-card-top'>
                            <img className="events-vertical-card-userimage" src={verify_img} alt="Error in loading" />
                        </div>
                        <div className='events-vertical-card-bottom'>
                            <h1 className='events-vertical-name-text'>Verification</h1> 
                        </div>
                    </div>
                    
                    <div className='events-vertical-card' onClick={() => { navigate("/fnb"); }}>
                        <div className='events-vertical-card-top'>
                            <img className="events-vertical-card-userimage" src={FnB_img} alt="Error in loading" />
                        </div>
                        <div className='events-vertical-card-bottom'>
                            <h1 className='events-vertical-name-text'>F & B</h1> 
                            
                        </div>
                        
                    </div>
                    
                    <div className='events-vertical-card' onClick={() => { navigate("/allocate"); }}>
                        <div className='events-vertical-card-top'>
                            <img className="events-vertical-card-userimage" src={Allocate_img} alt="Error in loading" />
                        </div>
                        <div className='events-vertical-card-bottom'>
                            <h1 className='events-vertical-name-text'>Allocate</h1> 
                            
                        </div>
                        
                    </div>
                    
                    
                    
                    
                </div>
                
            </div>
            
        </div>
        

    )
}

export default Events












