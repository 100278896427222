import React from "react";
import "./SeatDialog.css";

const SeatDialog = ({ seat, position, playerData }) => {
    if (!seat) return null;
    if (playerData){
        console.log(playerData)
    }
    const style = {
        top: position.top !== undefined ? position.top : undefined,
        bottom: position.bottom !== undefined ? position.bottom : undefined,
        left: position.left !== undefined ? position.left : undefined,
        right: position.left === undefined ? position.right : undefined
    };

    return (
        <div className="seat-dialog" style={style}>
            {playerData ? (<>
                <img className='seatDialog-pfp' src={playerData.pfp}/>
                </>
            ) : null}
            <div>
                {playerData ? (
                    <p>{playerData.name}</p>
                ) : null}
                <p><strong>{seat.seatRow}-{seat.seatNumber}</strong></p>
                {/* {seat.status === "Available" && (<p><strong>{seat.status}</strong></p>)} */}
                <p><strong>{seat.status}</strong></p>
            </div>
        </div>
    );
};

export default SeatDialog;
