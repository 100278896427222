import { Outlet } from "react-router-dom"
import useAuth from '../hooks/useAuth';
import { Navbar, Footer } from './';

const Layout = () => {
    const {auth} = useAuth();
    return (
        <main className="App">
            {auth.accessToken && <Navbar/>}
            <Outlet />
            {/* <Footer/> */}
        </main>
    )
}

export default Layout